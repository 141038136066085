<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1269_37150" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" transform="matrix(1 0 0 -1 0 24)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_1269_37150)">
      <path d="M11.8497 4C12.023 4.00677 12.2234 4.07813 12.3497 4.197L16.4711 8.07586C16.7073 8.33864 16.8047 8.84274 16.5316 9.13646C16.2626 9.42579 15.741 9.41717 15.471 9.12902L12.577 6.40176V19.2727C12.577 19.6743 12.2513 20 11.8497 20C11.4481 20 11.1224 19.6743 11.1224 19.2727V6.40176L8.22836 9.12902C7.97978 9.37726 7.43966 9.42309 7.16776 9.13646C6.89567 8.85 6.98259 8.32798 7.22846 8.07586L11.3498 4.197C11.4908 4.06426 11.6564 3.99966 11.8498 4H11.8497Z" fill="#0D092E"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconSortAsc"
}
</script>

<style lang="scss" scoped>

</style>